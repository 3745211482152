'use strict'

###*
 # @ngdoc object
 # @name mundoComponents.controller:MundoComponentsCtrl

 # @description

###
class MundoComponentsCtrl
  ### @ngInject ###
  constructor:(
    ConfirmBox,
    $log
  ) ->
    @ctrlName = 'MundoComponentsCtrl'

    @showConfirm = ->
      ConfirmBox.showConfirmDialog('Confirm box title', 'Confirm box massage')
      .then ->
        $log.debug 'Confirm box submitted'
      , ->
        $log.debug 'Confirm box canceled'

angular
  .module('mundoComponents')
  .controller 'MundoComponentsCtrl', MundoComponentsCtrl
